import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/casenotes`

export default {
  caseNote() {
    return {
      create: async data => {
        const endpoint = `${URL}`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAll: async params => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getOne: async ({ caseId }) => {
        const endpoint = `${URL}/${caseId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      update: async ({ caseId }, data) => {
        const endpoint = `${URL}/${caseId}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      delete: async ({ caseId }) => {
        const endpoint = `${URL}/${caseId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
